import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Row, Column, Text } from "components";
import { useGetDashboardQuery } from "features/dashboard/dashboardApiSlice";

const StatBoxes = ({ entries, ...restProps }) => {
  const { data: result } = useGetDashboardQuery();

  return (
    <>
      <div className="w-[250px] sm:w-[190px] md:w-[210px] lg:w-[230px] xl:w-[250px]">
        <Column class="grid grid-cols-2 gap-2">
          <Row class="grid gap-2">
            <div
              onClick={entries[0].onClick}
              className="w-[120px] sm:w-[90px] md:w-[100px] md:h-[100px] lg:w-[110px] h-[120px] sm:h-[90px] md:h-[100px] lg:h-[110px] xl:w-[120px] xl:h-[120px]"
              style={{ borderRadius: "12px", backgroundColor: "#283660" }}
            >
              <Column className="mt-[20px]">
                <Text
                  className="not-italic text-indigo_900 text-center"
                  variant="h6"
                >
                  {entries[0].title}
                </Text>
                <Text
                  className="mt-[5px] not-italic  text-indigo_900 text-center"
                  variant="h1"
                >
                  {result?.suppliers}
                </Text>
              </Column>
            </div>
            <div
              // onClick={entries[1].onClick}
              className="w-[120px] sm:w-[90px] md:w-[100px]  h-[120px] sm:h-[90px] md:h-[100px] lg:w-[110px] lg:h-[110px] xl:w-[120px] xl:h-[120px]"
              style={{ borderRadius: "12px", backgroundColor: "#283660" }}
            >
              <Column className="mt-[20px]">
                <Text
                  className="not-italic text-indigo_900 text-center"
                  variant="h6"
                >
                  {entries[1].title}
                </Text>
                <Text
                  className="mt-[5px] not-italic text-indigo_900 text-center "
                  variant="h1"
                >
                  {result?.total_products}
                </Text>
              </Column>
            </div>
          </Row>
          <Row class="grid gap-2">
            <div
              onClick={entries[2].onClick}
              className="w-[120px] sm:w-[90px] md:w-[100px]  h-[120px] sm:h-[90px] md:h-[100px]  lg:w-[110px] lg:h-[110px] xl:w-[120px] xl:h-[120px]"
              style={{ borderRadius: "12px", backgroundColor: "#283660" }}
            >
              <Column className="mt-[20px]">
                <Text
                  className="not-italic text-indigo_900 text-center"
                  variant="h6"
                >
                  {entries[2].title}
                </Text>
                <Text
                  className="mt-[5px] not-italic text-indigo_900 text-center "
                  variant="h1"
                >
                  {result?.laboratories}
                </Text>
              </Column>
            </div>
            <div
              onClick={entries[3].onClick}
              className="w-[120px] sm:w-[90px] md:w-[100px]  h-[120px] sm:h-[90px] md:h-[100px]  lg:w-[110px] lg:h-[110px] xl:w-[120px] xl:h-[120px]"
              style={{ borderRadius: "12px", backgroundColor: "#283660" }}
            >
              <Column className="mt-[20px]">
                <Text
                  className="not-italic text-indigo_900 text-center"
                  variant="h6"
                >
                  {entries[3].title}
                </Text>
                <Text
                  className="mt-[5px] not-italic text-indigo_900 text-center"
                  variant="h1"
                >
                  {result?.organizations}
                </Text>
              </Column>
            </div>
          </Row>
        </Column>
      </div>
    </>
  );
};
StatBoxes.propTypes = {
  entries: PropTypes.array,
};
StatBoxes.defaultProps = {
  entries: [
    { title: "Suppliers", number: "14", onClick: () => {} },
    { title: "Global Inventory", number: "14679", onClick: () => {} },
    { title: "Laboratories", number: "16", onClick: () => {} },
    { title: "Organizations", number: "12", onClick: () => {} },
  ],
};
export default StatBoxes;
