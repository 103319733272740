import React from "react";
import PropTypes from "prop-types";
import { faBuilding, faVials } from "@fortawesome/free-solid-svg-icons";

import { Column, Row, Img, Text, Button } from "components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Done for all breakpoints
const TopList = ({
  icon,
  entries = [{}],
  title,
  subtitle,
  seeMore,
  clickable=true,
  see = true,
  ...restProps
}) => {
  let list = entries.map((item, index) => {
    return (
      <div
        className={clickable?"hover:bg-gray_100 rounded-lg":"rounded-lg"}
        key={index}
        onClick={item.clicked}
      >
        <Row className="mt-[9px] sm:mt-[7px] md:mt-[9px] lg:mt-[9px] xl:mt-[10px] 2xl:mt-[12px] 3xl:mt-[14px]">
          <div className="text-[2.3rem] sm:text-[1.6rem] md:text-[1.8rem] lg:text-[2rem] xl:text-[2rem] rounded-lg px-[20px] sm:px-[10px] md:px-[15px] lg:px-[18px] xl:px-[18px] 2xl:px-[24px] 3xl:px-[27px] py-[10px] sm:py-[5px] md:py-[7px] lg:py-[9px] xl:py-[12px] 2xl:py-[14px] 3xl:py-[27px] bg-green_400 ">
            <FontAwesomeIcon
              icon={icon == "building" ? faBuilding : faVials}
              width={30}
              color="#ffffff"
            />
          </div>
          <Column className="ml-[12px] sm:ml-[9px] md:ml-[13px] lg:ml-[17px] xl:ml-[22px] 2xl:ml-[28px] 3xl:ml-[32px] mt-[12px] sm:mt-[4px] md:mt-[6px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[16px] 3xl:mt-[19px]">
            <Text className="not-italic text-bluegray_800" variant="body2">
              {item.company}
            </Text>
            <Text className="soldCounter" variant="body3">
              {item.desc}
            </Text>
          </Column>
        </Row>
      </div>
    );
  });
  const none = (
    <div className="h-[300px] pt-[100px]">
      <Text>No data to preview</Text>
    </div>
  );

  return (
    <>
      <Column>
        <Text className="not-italic text-gray_900 w-[auto]" variant="h4">
          {title}
        </Text>
        <Text
          className="mt-[2px] sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
          {subtitle}
        </Text>
        {list == undefined || list?.length == 0 ? (
          none
        ) : (
          <>
            {list}
            {see ? (
              <div className="hover:bg-gray_100 w-[20%] rounded-lg">
                <Text
                  className="common-button ml-[10%] mt-[10px] sm:mt-[10px] md:mt-[13px] lg:mt-[16px] xl:mt-[19px] 2xl:mt-[22px] 3xl:mt-[26px]  not-italic text-green_400"
                  variant="body3"
                  onClick={seeMore}
                >
                  See more
                </Text>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Column>
    </>
  );
};
TopList.propTypes = {
  icon: PropTypes.string,
  entries: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  seeMore: PropTypes.func,
};
TopList.defaultProps = {
  icon: "building",
  entries: [
    { company: "NEB", desc: 500, clicked: () => {} },
    { company: "Illumina", desc: 400, clicked: () => {} },
    { company: "BioPerfectus", desc: 500, clicked: () => {} },
  ],
  title: "Top 4 Suppliers",
  subtile: "50 Total Suppliers",
  seeMore: () => {},
};
export default TopList;
