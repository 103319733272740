

import { Pagination } from '@mui/material';
import { Text } from 'components/Text';
import React from 'react';

const Paginationn = ({ dataPerPage, totaldata, paginate }) => {
  // return <Pagination count={dataPerPage}  />
   const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totaldata / dataPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Pagination count={Math.ceil(totaldata / dataPerPage)} onChange={(event, page) => paginate(page)} />)
    // <nav>
    //   <ul className='pagination'>
    //     {pageNumbers.map(number => (
    //       <li key={number} className='page-item'>
    //         <Text  style={{borderRadius:10, backgroundColor: "rgba(79, 200, 120, 0.2)", color:'darkblue', padding:7,margin:5 }} onClick={() => paginate(number)}  className='text-gray_900 '>
    //           {number}
    //         </Text>
    //       </li>
    //     ))}
    //   </ul>
    // </nav>
  // ); 
};

export default Paginationn;