import { Input, Text, Button, Column } from "components/";
import { Row } from "components/Row";
import { Formik, Form, useFormikContext, useField } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import {
  useCreateInstitutionMutation,
  useCreateSupplierMutation,
} from "features/institution/institutionApiSlice";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import Toast from "components/Toast";
import { LocationCityRounded } from "@material-ui/icons";
import * as Yup from "yup";
import SelectLocation from "components/Mappp";
import { store } from "app/store";

function SupplierTabComponent() {
  const mapRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const [createSupplier, { isLoading }] = useCreateSupplierMutation();
  const [useMap, setUseMapp] = useState(true);
  const validationSchema = Yup.object().shape({
    // item: Yup.object().required("Item Required"),
    // brand: Yup.string().required("Brand Required"),
    name: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .min(10, "Must be a valid phone number"),
    country: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    email: Yup.string().email().required("Valid Email Required"),
    address: Yup.string().required("Required"),
    storeAddress: Yup.string(),
  });
  const initialValues = {
    name: "",
    phone: "",
    country: "",
    city: "",
    email: "",
    address: "",
    storeAddress: "",
    location_lat: 9.0013235,
    location_lon: 38.7670247,
    location_name:
      "Rizq Building | Gazebo Square | ሪዝቅ ህንጻ | ጋዜቦ አደባባይ, Addis Ababa, Ethiopia",
  };

  const onLoad = useCallback((map) => (mapRef.current = map), []);

  return (
    <div style={{ overflow: "scroll", height: "50vh" }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const institutionData = await createSupplier({
              name: values.name,
              country: values.country,
              city: values.city,
              email: values.email,
              phone: values.phone,
              address: values.address,
              store_address: useMap
                ? values.location_name
                : values.storeAddress,
              store_location: {
                longitude: values.location_lon,
                latitude: values.location_lat,
              },
              category: "supplier",
            }).unwrap();
            setErrMsg("");
            setSuccessMsg("Successfully created supplier");

            resetForm();
          } catch (err) {
            if (!err) {
              setErrMsg("No Server Response");
            } else if (err.data?.store_address)
              setErrMsg("Store address can not be empty");
            else if (err.status === 400) {
              setErrMsg("Invalid Credentials");
            } else if (err.status === 401) {
              setErrMsg("Unauthorized");
            } else {
              setErrMsg("Failed to create a supplier");
            }
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Column className="gap-2">
              {isLoading && (
                <Box
                  sx={{
                    position: "relative",
                    top: 0,
                    width: "100%",
                    height: "50px",
                  }}
                >
                  <LinearProgress className="bg-green_400" />
                </Box>
              )}
              {errMsg && <Toast message={errMsg} />}
              {successMessage && (
                <Toast type="success" message={successMessage} />
              )}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Name
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="name"
                  {...formik.getFieldProps("name")}
                ></Input>
              </Row>
              {formik.touched?.name && formik.errors?.name ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.name}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Phone Number
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="phone"
                  {...formik.getFieldProps("phone")}
                ></Input>
              </Row>
              {formik.touched?.phone && formik.errors?.phone ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.phone}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900" variant="h6">
                  Email Address
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="email"
                  {...formik.getFieldProps("email")}
                ></Input>
              </Row>
              {formik.touched?.email && formik.errors?.email ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.email}
                  </Text>
                </div>
              ) : null}

              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Address
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="address"
                  {...formik.getFieldProps("address")}
                ></Input>
              </Row>
              {formik.touched?.address && formik.errors?.address ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.address}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Country
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="country"
                  {...formik.getFieldProps("country")}
                ></Input>
              </Row>
              {formik.touched?.country && formik.errors?.country ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.country}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  City
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="city"
                  {...formik.getFieldProps("city")}
                ></Input>
              </Row>
              {formik.touched?.city && formik.errors?.city ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.city}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text className="not-italic text-gray_900 " variant="h6">
                  Store Address
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName={
                    useMap
                      ? "bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                      : "2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  }
                  name="storeAddress"
                  disabled={useMap}
                  {...formik.getFieldProps("storeAddress")}
                ></Input>
              </Row>

              <Row className="items-center grid grid-cols-1">
                <FormGroup className="ml-[4%]">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setUseMapp(e.target.checked)}
                        defaultChecked
                      />
                    }
                    label="Use the location name on the Map as my store address"
                  />
                </FormGroup>
              </Row>
              <SelectLocation
                placeholder={"Enter Store Location on Map"}
                disabled={true}
              />

              <Button
                type="submit"
                className="w-[200px]"
                children={<Text>Create Supplier</Text>}
              ></Button>
            </Column>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default SupplierTabComponent;
