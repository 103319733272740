import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  faUser,
  faDownload,
  faCheckCircle,
  faBoxesStacked,
  faCheck,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { Column, Row, Img, Text, Button, Input } from "components";
import { useNavigate } from "react-router-dom";
import { Icon, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUpdateCartQuantityMutation } from "features/order/ordersApiSlice";

const CardForItems = ({
  icon,
  row11,
  row12,
  row21,
  row22,
  id,
  qty,
  button2name,
  button2color,
  button1action,
  button2action,
  button2Icon,
  editButtonAction,
  ...restProps
}) => {
  const navigate = useNavigate();
  const [updateCart, { isLoadingOrder }] = useUpdateCartQuantityMutation();
  const [quantity, setQuantity] = useState(qty);
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");

  return (
    <>
      <div
        style={{
          borderRadius: 10,
          backgroundColor: "rgba(40, 54, 96, 0.2)",
        }}
        className="w-[100%] inline-block h-[60px] sm:h-[60px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
      >
        <Row className="mx-3">
          <div className="w-[15%] mr-[5%] sm:mt-[3%] md:mt-[4%] mt-[5%]">
            {
              <FontAwesomeIcon
                type="outline"
                className="px-[auto]"
                color="#ffffff"
                fontSize={30}
                width={40}
                icon={faBoxesStacked}
              />
            }
          </div>
          <Column className="grid grid-rows-2 mt-[8px] sm:mt-[9px]  md:mt-[10px] lg:mt-[13px] xl:mt-[18px] w-[30%]">
            {/* <Text className="not-italic text-[20px] text-gray_900" variant="h6"> */}
            {row11}
            {/* </Text> */}
            <Text className="text-bluegray_300" variant="h6">
              {row12}
            </Text>
          </Column>

          <Column className="justify-self-end w-[40%] mt-[15px] xl:mt-[25px] ">
            <Text variant="h4">{row21}</Text>
            <Text className="text-bluegray_300" variant="h6">
              {row22}
            </Text>
          </Column>

          {button2name == "" ? (
            <div className="w-[50%] my-[auto] py-[auto] grid grid-cols-3">
              {/* {button2Icon == null ? ( */}
              {/* <Input
                className="w-[100%]"
                wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px]  flex h-[30px] mt-[6px] sm:h-[36px] sm:mt-[7px] md:h-[40px] md:mt-[8px] lg:h-[40px] lg:mt-[9px] w-[100%] xl:h-[45px] xl:mt-[10px]"
                name="username"
                id="username"
                // {...formik.getFieldProps("username")}
              ></Input> */}
              <div className="mt-[15px] md:mt-[10px] lg:mt-[25px]">
                <input
                  type={"number"}
                  className="h-[50%] w-[10vh] rounded-radius845 bg-gray_50 border border-gray_50 border-solid lg:p-[10px] xl:p-[13px] p-[15px] 3xl:p-[18px]"
                  onChange={(e) => {
                    setQuantity(e.target.value);
                  }}
                  // width={20}
                  value={quantity}
                />
              </div>
              <Button
                variant="None"
                className="ml-[auto] h-[40px] w-[10%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px]"
                onClick={async () => {
                  try {
                    const itemData = await updateCart({
                      id: id,
                      quantity: quantity,
                    }).unwrap();
                    setErrMsg("");
                    setSuccessMsg("Successfully updated quantity");
                  } catch (err) {
                    if (!err) {
                      setErrMsg("No Server Response");
                    } else if (err.data?.deliver_by)
                      setErrMsg("Delivery Address can not be empty");
                    else if (err.data?.deliver_to)
                      setErrMsg("Delivery Time can not be empty");
                    else if (err.status === 400) {
                      setErrMsg("Invalid Credentials");
                    } else if (err.status === 401) {
                      setErrMsg("Unauthorized");
                    } else {
                      setErrMsg("Failed to create order");
                    }
                  }
                }}
                children={
                  <FontAwesomeIcon
                    type="outline"
                    className="px-[auto]"
                    color={"#ffffff"}
                    fontSize={30}
                    width={20}
                    icon={faEdit}
                  />
                }
              ></Button>
              <Button
                variant="None"
                className="ml-[auto] h-[40px] w-[50%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px]"
                onClick={button2action}
                children={
                  <FontAwesomeIcon
                    type="outline"
                    className="px-[auto]"
                    color={button2color ? button2color : "#ffffff"}
                    fontSize={30}
                    width={20}
                    icon={button2Icon}
                  />
                }
              ></Button>
            </div>
          ) : (
            <div className="w-[20%]">
              <Button
                variant={button2color ? button2color : "FillIndigo900cc"}
                className="ml-[auto] h-[40px] w-[80%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px] "
                onClick={button2action}
                children={<Text variant="body1">{button2name}</Text>}
              ></Button>
            </div>
          )}
        </Row>
      </div>
    </>
  );
};
CardForItems.propTypes = {
  icon: PropTypes.object,
  row11: PropTypes.object,
  row12: PropTypes.object,
  row21: PropTypes.string,
  row22: PropTypes.string,
  button1name: PropTypes.string,
  button1color: PropTypes.string,
  button2name: PropTypes.string,
  button2color: PropTypes.string,
  button1action: PropTypes.func,
  button2action: PropTypes.func,
  button2Icon: PropTypes.object,
  button1Icon: PropTypes.object,
};
CardForItems.defaultProps = {
  icon: (
    <FontAwesomeIcon
      type="outline"
      className="px-[auto]"
      color="#ffffff"
      fontSize={30}
      width={40}
      icon={faUser}
    />
  ),
  // row11: "",
  // row12: "",
  row21: "",
  row22: "",
  button1name: "",
  button1Icon: { faDownload },
  button2Icon: { faCheckCircle },
  button1color: "FillIndigo900cc",
  button2name: "",
  button2color: "FillIndigo900cc",
  button1action: () => {
  },
  button2action: () => {
  },
};
export default CardForItems;
