import {
  Autocomplete,
  Box,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Input, Column, Text, Button, Radio, RadioGroup } from "components/";
import { Row } from "components/Row";
import Toast from "components/Toast";
import * as Yup from "yup";

import { useGetReferenceItemsQuery } from "features/item/referenceItemApiSlice";
import { useUpdateItemMutation } from "features/item/itemApiSlice";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
function EditItemsModal({ data }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    p: 5,
  };
  const initialValues = {
    // item:  { id: `${data.item}`, name: '', description:''},
    price: `${data.price}`,
    quantity: `${data.quantity}`,
    catalogNumber: "",
    brand: "",
  };
  const validationSchema = Yup.object().shape({
    // itemname: Yup.object().required("Item Name is required"),
    brand: Yup.string().required("Brand Name is required"),
    price: Yup.number().required("Price is required"),
    quantity: Yup.number().required("Quantity  is required"),
    catalogNumber: Yup.number().required("Catalog Number is required"),
  });

  const {
    data: dataa,
    isLoadingg,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetReferenceItemsQuery();
  const [editItem, { isLoading }] = useUpdateItemMutation();
  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  return (
    <Box className="w-[60%] md:w-[600px]" sx={style}>
      {isLoadingg && (
        <Box
          sx={{ position: "absolute", top: 0, width: "100%", height: "50px" }}
        >
          <LinearProgress className="bg-green_400" />
        </Box>
      )}
      <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
        Edit Item
      </Text>
      <Formik
        initialValues={initialValues}
        //  validationSchema={validationSchema}

        onSubmit={async (values, { resetForm }) => {
          try {
            const itemData = await editItem({
              id: data.id,
              item: data.id,
              quantity: values.quantity,
              price: values.price,
            }).unwrap();
            setErrMsg("");
            setSuccessMsg("Successfully editted item");
            resetForm();
          } catch (err) {
            if (!err) {
              setErrMsg("No Server Response");
            } else if (err.status === 400) {
              setErrMsg("Invalid Credentials");
            } else if (err.status === 401) {
              setErrMsg("Unauthorized");
            } else {
              setErrMsg("Failed to create a global item");
            }
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Column className="gap-2 mt-[10px]">
              {errMsg && <Toast message={errMsg} />}
              {successMessage && (
                <Toast type="success" message={successMessage} />
              )}
              {/* <Row className='items-center grid grid-cols-1 md:grid-cols-3'>

        <Text className="not-italic text-gray_900 w-[150px]" variant="h6">
                     Item Name
                </Text>
                <Autocomplete
        id="item"
        name="item"
        onChange={(e, value) => {
          formik.setFieldValue(
             "item",
             value !== null ? value : initialValues.item
             );
         }}     
        options={dataa?dataa:[]}
        className= ' w-[160px] sm:w-[300px] rounded-radius845 border border-gray_50 border-solid'
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            className='bg-gray_50'
            variant="outlined"
            name="item"
          />
        )}
      />
</Row> */}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px] mb-2"
                  variant="h6"
                >
                  Brand
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="brand"
                  {...formik.getFieldProps("brand")}
                ></Input>
              </Row>
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px] mb-2"
                  variant="h6"
                >
                  Price
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="price"
                  {...formik.getFieldProps("price")}
                ></Input>
              </Row>
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px] mb-2"
                  variant="h6"
                >
                  Quantity
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="quantity"
                  {...formik.getFieldProps("quantity")}
                ></Input>
              </Row>
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px] mb-2"
                  variant="h6"
                >
                  Catalog Number
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="catalogNumber"
                  {...formik.getFieldProps("catalogNumber")}
                ></Input>
              </Row>
              <Button type="submit" className="w-[200px] mt-4">
                Edit Item{" "}
              </Button>
            </Column>
          </form>
        )}
      </Formik>
    </Box>
  );
}
EditItemsModal.propTypes = {
  data: PropTypes.object,
};
EditItemsModal.defaultProps = {
  data: {
    catalogNumber: "EP5G89",
    quantity: 30,
    price: 40.0,
    brand: "Gofen",
    itemname: "Ibuprofen",
  },
};

export default EditItemsModal;
