import React, { useEffect, useState } from "react";
import { Badge, Box, Modal, Pagination } from "@mui/material";
import { Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import BasicPagination from "../../../components/Pagination/index";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {
  faBoxesStacked,
  faCartShopping,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Proforma from "components/Modals/Proforma";
import Cart from "components/Modals/Cart";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import {
  useGetCartItemsQuery,
  useGetOffersClientQuery,
  useGetOrdersClientQuery,
} from "features/order/ordersApiSlice";
import Paginationn from "../../../components/Pagination/index";
import { usePagination } from "@mui/lab";
import { BounceLoader } from "react-spinners";
import Footer from "components/Footer";
const OffersPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [x, setX] = React.useState();
  const [count,setCount] = useState(0);

  const [open, setOpen] = React.useState(false);
  const { data, isLoading } = useGetOffersClientQuery();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = data?.slice(indexOfFirstData, indexOfLastData);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const {
    data:cartItems,
    isLoading: isLoadingg,
   
    isError,
    error,
  } = useGetCartItemsQuery();
  const editableCart = structuredClone(cartItems);
  useEffect(() => {
    setCount(editableCart?.length)
  }, [cartItems])
  

  var menu = [
    {
      title: "Items",
      dropdown: false,
      route_path: () => navigate("/organization/orderitem"),
      icon: "",
    },
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "Offers", route_path: () => navigate("/organization/offers") },
        {
          title: "Order History",
          route_path: () => navigate("/organization/order"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/organization/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
    {
      title: "",
      route_path: () => {
        chooseModal(2);
        handleOpen();
      },
      icon: faCartShopping,
    },
  ];
  function chooseModal(idx, id) {
    switch (idx) {
      case 1:
        setX(<Proforma title="Proformas" id={id} desc="Order 345667" />);
        break;
      case 2:
        setX(<Cart />);
        break;
    }
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // let [page, setPage] = useState(1);
  // const PER_PAGE = 24;

  // const count = Math.ceil(data.length / PER_PAGE);
  // const _DATA = usePagination(data, PER_PAGE);

  // const handleChange = (e, p) => {
  //   setPage(p);
  //   _DATA.jump(p);
  // };
  const list = currentData?.map((element) => {
    return (
      <Card
        modal={false}
        icon={
          <FontAwesomeIcon
            type="outline"
            className="px-[auto]"
            color="#ffffff"
            fontSize={30}
            width={40}
            icon={faBoxesStacked}
          />
        }
        row11={"Order " + element?.id}
        // row21="Arsho"
        row12="4 Proformas"
        row42={element?.status}
        row41={element?.deliver_by}
        button2name="View"
        button2action={() => {
          chooseModal(1, element?.id);
          handleOpen();
        }}
      />
    );
  });
  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "scroll" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <Proforma title="Proformas" desc="Order 345667" />}
      </Modal>
      <Header homePath="/organization" menus={menu} cart={
          <div onClick={()=>{ chooseModal(13);
            handleOpen();}}>
            
            <Badge
              className="px-[auto] mx-2"
              color="primary"
              badgeContent={count}
            >
              <ShoppingCartIcon htmlColor="#ffffff" />
            </Badge>
          </div>
        } />
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <Column className="mx-[auto] pb-[5rem] w-[90%] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
          <Row className="mb-[3%] content-end grid grid-cols-2">
            <Text variant="h1" className=" not-italic text-gray_900">
              Offers by SO-YO
            </Text>
          </Row>

          {list}

          <Paginationn
            dataPerPage={dataPerPage}
            totaldata={data?.length}
            paginate={paginate}
          />
        </Column>
      )}
      <Footer />
    </>
  );
};

export default OffersPage;
