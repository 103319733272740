import React from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useGetReferenceItemQuery } from "features/item/referenceItemApiSlice";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { CircularProgress, LinearProgress } from "@mui/material";
import { Column } from "components/Column";
import { Text } from "components/Text";
// const Item = styled(Paper)(({ theme }) => ({
//     ...theme.typography.body2,
//     textAlign: 'center',
//     color: 'rgba(40, 54, 96, 0.2)',
//     // lineHeight: '60px',
//   }));
const KitItems = (id) => {
  const { data, isLoading, isSuccess, isError, refetch, isFetching, error } =
    useGetReferenceItemQuery(id.id);
  return (
    <>
      {isLoading ? (
        <Box
          sx={{ position: "relative", top: "50%", left: "50%", height: "30px" }}
        >
          <CircularProgress color="info" />
        </Box>
      ) : (
        <Box
          className=""
          sx={{
            p: 2,
            bgcolor: "rgba(40, 54, 96, 0.2)",
            // gridTemplateColumns: { xl: "1fr 1fr" },
          }}
        >
          <Paper
            className="w-[100%] h-[auto] items-center justify-center"
            elevation={4}
          >
            <Column>
              <Text
                variant="h5"
                className="text-gray_900 text-center"
                children={"Name: " + data?.name}
              />
              <Text
                className="text-gray_900 text-center"
                children={"Brand: " + data?.brand}
              />
              <Text
                className="text-gray_900 text-center"
                children={"Description: " + data?.description}
              />
            </Column>
          </Paper>
        </Box>
      )}
      {/* </div> */}
    </>
  );
};
KitItems.propTypes = {
  id: PropTypes.number.isRequired,
};
export default KitItems;
