import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Pagination,
  Select,
} from "@mui/material";
import { Button, Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import EditAccounts from "components/Modals/EditAccount/index.js";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CreateAccount from "components/Modals/CreateAccount";
import ViewAccount from "components/Modals/ViewUsers";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} from "features/users/usersApiSlice";
import MaterialTable from "material-table";
import EnhancedTable from "components/TableEnhanced";
import { useGetInstitutionsQuery } from "features/institution/institutionApiSlice";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { BounceLoader } from "react-spinners";
import UserCreateModal from "components/Modals/CreateAccount/user";
import { MuiThemeProvider } from "@material-ui/core";
import Footer from "components/Footer";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ViewUsersPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [errMsg, setErrMsg] = useState("");
  const [idToDelete, setIdToDelete] = useState("");
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [q, setQ] = useState("");
  const [deleteUser, { isLoadingDelete }] = useDeleteUserMutation();
  const [updateUser, { isLoadingUpdate }] = useUpdateUserMutation();

  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetAllUsersQuery();
  const editable = structuredClone(records);
  const selectedRow = React.useRef([]);
  const [rowId, setRowId] = useState();
  const [successMessage, setSuccessMsg] = useState("");
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };
  const { data: result } = useGetInstitutionsQuery();
  var lookupObject = result?.reduce(function (acc, cur, i) {
    acc[cur.id] = cur.name;

    return acc;
  }, {});
  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "UserName",
      field: "username",
      align: "left",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "First Name",
      field: "first_name",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.first_name === undefined || rowData.first_name == "") {
          return "Required";
        } else return true;
      },
    },
    {
      title: "Last Name",
      field: "last_name",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.last_name === undefined || rowData.last_name == "") {
          return "Required";
        } else return true;
      },
    },
    {
      title: "Email Address",
      field: "email",
      align: "left",
      editable: false,
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.email === undefined || rowData.email === "") {
          return "Required";
        } else if (!rowData.email.includes("@" && ".")) {
          return "Enter valid Email Address";
        } else return true;
      },
    },

    {
      title: "Phone Number",
      field: "phone",
      align: "left",
      type: "numeric",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.phone == "") {
          return "Required";
        } else if (rowData.phone.length < 10 || rowData.phone.length > 10) {
          return "Enter a valid phone number";
        } else return true;
      },
    },
    {
      title: "Position",
      field: "position",
      align: "left",
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.position === undefined || rowData.position == "") {
          return "Required";
        } else return true;
      },
    },

    {
      title: "Role",
      field: "role",
      align: "left",
      lookup: {
        admin: "Administrator",
        client: "Client",
        supplier: "Supplier",
        soyo: "Soyo-Officer",
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Institution",
      field: "institution",
      align: "left",
      lookup: lookupObject,
      filterPlaceholder: "Filter",
    },
  ];

  const handleDelete = async (value) => {
    try {
      const userData = await deleteUser(rowId).unwrap();
      setErrMsg("");
      setSuccessMsg("Successfully deleted user");
    } catch (err) {
      if (!err) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Invalid Credentials");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Failed to delete user");
      }
    }
  };
  const [openDia, setOpenDia] = React.useState(false);
  const handleClickOpen = () => {
    setOpenDia(true);
  };
  const handleClickClose = () => {
    setOpenDia(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const theme = createMuiTheme({
  //   palette: {
  //     primary: {
  //       main: "#ffffff",
  //     },
  //     secondary: {
  //       main: "#283660",
  //     },
  //     divider: "#ffffff",
  //   },
  // });
  function chooseModal(idx, data) {
    switch (idx) {
      case 1:
        setX(<UserCreateModal />);
        break;
      case 2:
        setX(<CreateAccount />);
        break;
      case 3:
        setX(
          <ViewAccount
            username={data.username}
            fullName={data.first_name + " " + data.last_name}
            position={data.position}
            category={data.role}
            institution={data.institution}
            phone={data.phone}
            email={data.email}
          />
        );
        break;
    }
  }
  var menu = [
    {
      title: "Accounts",
      dropdown: true,
      entries: [
        // {
        //   title: "Add User",
        //   route_path: () => {
        //     chooseModal(2);
        //     handleOpen();
        //   },
        // },
        {
          title: "Users Management",
          route_path: () => {
            navigate("/dashboard/users");
          },
        },
        {
          title: "Institutions Management",
          route_path: () => {
            navigate("/dashboard/institutions");
          },
        },
      ],
      icon: "",
    },

    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/dashboard/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];

  return (
    <>
      {/* { isLoading &&  (<Box sx={{ position: 'absolute', top: 0, width: '100%', height:'50px' }}>
    <LinearProgress className="bg-green_400"/>
  </Box>)} */}

      <Modal
        open={open}
        sx={{ overflow: "fixed" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <EditAccounts />}
      </Modal>
      <Dialog
        open={openDia}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            border: "2px solid #ffffff",
            borderRadius: 3,
            boxShadow: 24,
          }}
          className="p-3"
        >
          <DialogTitle id="alert-dialog-title">
            <Text variant="h4">
              Are you sure you want to delete this user? All the data associated
              with that user will be removed.
            </Text>
          </DialogTitle>

          <DialogActions>
            <Button className="w-[15%]" onClick={handleClickClose}>
              No
            </Button>
            <Button
              variant="FillRed700cc"
              className="w-[15%]"
              onClick={() => {
                handleDelete();
                handleClickClose();
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Header homePath="/dashboard" menus={menu} />

      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <>
          <Column className="mx-[auto]  pb-[5rem] w-[90%] sm:w-[90%] md:w-[85%] lg:w-[85%] xl:w-[85%]">
            <Text variant="h1" className="not-italic text-gray_900 mb-[1%]">
              Users Management
            </Text>
            <Button
              onClick={() => {
                chooseModal(1);
                handleOpen();
              }}
              className="w-[20%] mb-[2%]"
            >
              Add User
            </Button>
            {/* <MuiThemeProvider theme={theme}> */}

            <MaterialTable
              editable={{
                onRowUpdate: (newRow, oldRow) =>
                  new Promise(async (resolve, reject) => {
                    try {
                      const userData = await updateUser({
                        id: oldRow.id,
                        //username: oldRow.username,
                        first_name: newRow.firstName,
                        last_name: newRow.lastName,
                        // email: oldRow.email,
                        phone: newRow.phone,
                        role: newRow.role,
                        institution: newRow.institution,
                        position: newRow.position,
                      }).unwrap();
                      setErrMsg("");
                      setSuccessMsg("Successfully edited user");
                    } catch (err) {
                      if (!err) {
                        setErrMsg("No Server Response");
                      } else if (err.status === 400) {
                        setErrMsg("Invalid Credentials");
                      } else if (err.status === 401) {
                        setErrMsg("Unauthorized");
                      } else {
                        setErrMsg("Failed to create a global item");
                      }
                    }
                    setTimeout(() => resolve(), 500);
                  }),
                onRowDelete: (Row) =>
                  new Promise(async (resolve, reject) => {
                    setRowId(Row.id);
                    setOpenDia(true);
                    setTimeout(() => resolve(), 500);
                  }),
              }}
              options={{
                showTitle: false,
                sorting: true,
                emptyRowsWhenPaging: false,
                search: true,
                filtering: true,
                pageSizeOptions: [5, 10, 15],
                exportButton: true,
                exportAllData: true,
                // selection: true,
                showSelectAllCheckbox: true,
                showTextRowsSelected: true,

                selectionProps: (rowData) => ({ color: "primary" }),
                headerSelectionProps: (header) => ({ color: "primary" }),
                searchFieldAlignment: "left",
                grouping: true,
                columnsButton: true,
                rowStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                  color: "#181c32",
                },

                headerStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                  color: "#181c32",
                  fontWeight: "bolder",
                },
                filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
                detailPanelColumnStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                },
                actionsColumnIndex: 10,
              }}
              onSelectionChange={(e) => {
                handleClick(e);
              }}
              columns={columns}
              data={editable}
              icons={tableIcons}
              // actions={[
              //   {
              //     icon: AddBox,
              //     tooltip: "Add User",
              //     isFreeAction: true,

              //     // iconProps: { htmlColor:"#ffffff"},
              //     onClick: (event) => {
              //       chooseModal(1);
              //       handleOpen();
              //     },
              //   },
              // ]}
            />
            {/* </MuiThemeProvider> */}
          </Column>
        </>
      )}
      <Footer />
    </>
  );
};

export default ViewUsersPage;
