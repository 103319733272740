import React from "react";

function Footer() {
  return (
    <footer
      style={{
        position: "fixed",
        width: "100%",
        // left: 0,
        // // right:0,
        // paddingBottom: '5rem',

        bottom: 0,
      }}
      // style={{ position: "absolute", bottom: 0, width:'100%' }}
      className="text-center lg:text-left"
    >
      <div
        className="text-gray-700 text-center p-4 mt-[2%]"
        style={{ backgroundColor: "rgba(40, 54, 96, 1)" }}
      >
        © SOYO {new Date().getFullYear()}. All Rights Reserved. v1.0.0
      </div>
    </footer>
  );
}

export default Footer;
