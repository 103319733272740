import React from "react";
import PropTypes, { nominalTypeHack } from "prop-types";

const shapes = {
  icbRoundedBorder8: "rounded-radius8",
  icbRoundedBorder4: "rounded-radius4",
  RoundedBorder4: "rounded-radius4",
  RoundedBorder8: "rounded-radius845",
};
const variants = {
  icbFillGreen400b2: "bg-green_400_b2",
  None: "",
  icbFillBluegray30087: "bg-bluegray_300_87",
  icbOutlineGreen400: "bg-white_A700 border border-green_400 border-solid",
  OutlineGreen400:
    "bg-white_A700 border border-green_400 border-solid text-gray_900",
  FillRed700cc: "bg-red_700_cc text-white_A700",
  FillIndigo900cc: "bg-indigo_900_cc text-white_A700",
  OutlineIndigo900cc:
    "bg-white_A700 border border-indigo_900_cc border-solid text-indigo_900_cc",
  FillGreenA400: "bg-green_A400 text-white_A700",
  FillGreen400: "bg-green_400 text-white_A700",
};
const sizes = {
  smIcn: "p-[4px]",
  mdIcn: "p-[10px] 3xl:p-[12px] lg:p-[7px] xl:p-[8px]",
  lgIcn: "xl:p-[11px] p-[13px] 3xl:p-[15px] lg:p-[9px]",
  sm: "lg:p-[3px] xl:p-[4px] p-[5px] 3xl:p-[6px]",
  md: "3xl:p-[10px] lg:p-[6px] xl:p-[8px] p-[9px]",
  lg: "xl:p-[10px] p-[12px] 3xl:p-[14px] lg:p-[8px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant,
  size,
  onClick,

  ...restProps
}) => {
  return (
    <button
      onClick={onClick}
      onenter
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={`${className} ${shapes[shape] || ""} ${
        variants[variant] || ""
      } ${sizes[size] || ""} common-button `}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  shape: PropTypes.oneOf([
    "icbRoundedBorder8",
    "icbRoundedBorder4",
    "RoundedBorder4",
    "RoundedBorder8",
  ]),

  variant: PropTypes.oneOf([
    "icbFillGreen400",
    "icbFillBluegray30087",
    "icbOutlineGreen400",
    "OutlineGreen400",
    "FillRed700cc",
    "FillIndigo900cc",
    "OutlineIndigo900cc",
    "FillGreenA400",
    "FillGreen400",
    "None",
  ]),
  size: PropTypes.oneOf(["smIcn", "mdIcn", "lgIcn", "sm", "md", "lg"]),
};
Button.defaultProps = {
  className: "mt-2",
  shape: "icbRoundedBorder8",
  variant: "FillGreen400",
  size: "mdIcn",
};

export { Button };
